<template>
  <b-modal
    class="popupNews"
    :id="popUp.src"
    :hide-footer="true"
    :hide-header="false"
    size="xl"
    scrollable
  >
    <template v-slot:modal-header>
      <div class="flex-total">
        <img
          style="height: 60px"
          src="https://santodomingocartagena.com/img/logo_santo_domingo.png"
          alt="logo santo domingo cartagena"
        />
      </div>
      <div class="pointer justify-content-end">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div style="text-align: center" @click="urlClick()">
      <img
        style="width: 100%"
        :src="`https://santodomingocartagena.com/img/gallery/${popUp.src}`"
        alt="logo Santo Domingo Cartagena"
      />
    </div>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../assets/components/close.vue";

export default {
  name: "popup",
  props: ["popUp"],

  components: {
    closeicon,
  },

  data() {
    return {
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  computed: {

  },
  methods: {
    urlClick(){
      if(this.popUp.url){
        window.open(this.popUp.url,'_self');
      }
    },
    closeModal() {
      this.$bvModal.hide(this.popUp.src);
    },
  },
  mounted() {
    this.popUp;
    if (!this.$cookies.get(this.popUp.title)) {
      let path = window.location.hostname;
      this.$cookies.set(this.popUp.title, undefined, 60 * 60 * 0.2, path);
      this.$bvModal.show(this.popUp.src);
    }
  },
};
</script>
<style></style>
