import home from "../views/sitioWeb/PrincipalPage.vue";
import vistaMedia from "../views/sitioWeb/VistaMedia.vue";
import vistaCarreteImagenes from "../views/sitioWeb/VistaCarreteImagenes.vue";
import vistaNoticias from "../views/sitioWeb/VistaNoticias.vue";
import vistaNoticia from "../views/sitioWeb/VistaNoticia.vue";
import songs from "../views/songs.vue";
import homeWebSite from "../views/HomeWebSite.vue";


import dashboard from "../views/Dashboard.vue";
import login from "../views/loginView.vue";


export const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
  },
  {
    path: "/",
    component: homeWebSite,
    children: [
      {
        path: "/",
        name: "inicio",
        component: home,
      },
      {
        path: "/noticias",
        name: "news",
        component: vistaNoticias,
        meta: { noCache: true },
      },
      {
        path: "/noticia",
        name: "noticia",
        component: vistaNoticia,
        meta: { noCache: true },
      },
      {
        path: "/media",
        name: "multimedia",
        component: vistaMedia,
        meta: { noCache: true },
      },
      {
        path: "/carrete",
        name: "carrete",
        component: vistaCarreteImagenes,
        meta: { noCache: true },
      },
    ],
  },
  { path: "/songs", name: "songs", component: songs, meta: { nocache: false } },
  { path: "*", redirect: "/", name: "inicio" },
];