<template>
  <div class="bodyCarrete" v-if="listaGallery.length > 0">
    <h1 class="title">{{ title }}</h1>
    <div class="wrapper">
      <template v-for="(item, index) in listaGallery">
        <cardView
          :imageSrc="item.urlImage"
          :imagetitle="item.titulo"
          :imageDescription="item.description"
          :key="index"
          :index="index"
          @index="getIndex"
        />
      </template>
    </div>
    <div class="backButton">
      <img
        :src="'https://santodomingocartagena.com/img/icon/backButton.png'"
        alt="atras"
        @click="$router.go(-1)"
      />
    </div>
    <LightBox
      ref="lightbox"
      :show-caption="true"
      :show-light-box="false"
      :media="onlyImage"
      :interfaceHideTime="300000"
    />
  </div>
  <div class="bodyCarrete" id="changeWeb" v-else>
    <loadingWebSite />
  </div>
</template>

<script>
import axios from "axios";
import loadingWebSite from "../../src/components/loadingwebsite.vue";
import cardView from "../../src/components/galery/cardView.vue";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack

export default {
  name: "carrete",
  props: [],
  components: {
    loadingWebSite,
    cardView,
    LightBox,
  },
  metaInfo() {
    return {
      title: `Santo Domingo Cartagena - ${this.title.replaceAll("_", " ")}`,
      meta: [
        {
          name: "description",
          content:
            "imagenes y videos sobre de actividades de la parroquia castrense de Santo Domingo Cartagena",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://santodomingocartagena.com${this.$route.fullPath}`,
        },
      ],
    };
  },
  data() {
    return {
      lista: [],
      listaGallery: [],
      title: "",
      onlyImage: [],
      index: null,
      imageSrc: undefined,
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    changeData() {
      let id = this.$route.query.id;
      let pass = 0;

      axios
        .get(`${this.baseApi}/apisd/galeria?id=` + id)
        .then((data) => {
          this.title = data.data[0].titulo;
          this.listaGallery = [];
          this.lista = data.data;
          this.lista.forEach((element) => {
            if (element.urlImage.includes("youtube")) {
              let idVideo = this.youTubeGetID(element.urlImage);
              idVideo =
                "https://img.youtube.com/vi/" + idVideo + "/hqdefault.jpg";
              this.listaGallery.push({
                urlImage: idVideo,
                titulo: element.titulo,
                description: element.description,
              });
            } else {
              if (pass === 0) {
                this.imageSrc = element.urlImage;
                pass++;
              }

              this.listaGallery.push({
                urlImage: element.urlImage,
                titulo: element.titulo,
                description: element.description,
              });
            }
          });
          this.modifyMeta();
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    youTubeGetID(url) {
      var ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_]/i);
        ID = ID[0];
      } else {
        ID = url;
      }
      return ID;
    },
    getIndex(event) {
      this.index = event.key;
      if (this.onlyImage.length === 0) {
        this.lista.forEach((element) => {
          if (!element.urlImage.includes("http")) {
            this.onlyImage.push({
              type: "image",
              // thumb: require("@/assets/img/imageGalery/" + element.urlImage),
              // src: require("@/assets/img/imageGalery/" + element.urlImage),
              thumb:
                "https://santodomingocartagena.com/img/gallery/" +
                element.urlImage,
              src:
                "https://santodomingocartagena.com/img/gallery/" +
                element.urlImage,
            });
          } else if (element.urlImage.includes("youtube")) {
            let idVideo = this.youTubeGetID(element.urlImage);
            this.onlyImage.push({
              type: "youtube",
              thumb: "https://img.youtube.com/vi/" + idVideo + "/hqdefault.jpg",
              id: idVideo,
            });
          }
        });
      }
      this.$refs.lightbox.showImage(this.index);
    },
    modifyMeta() {
      document.querySelector('head meta[property="og:url"]').content =
        document.location.href;
    },
  },
  mounted() {
    this.changeData();
  },
};
</script>
<style>
.bodyCarrete {
  padding-top: 120px;
  min-height: calc(100vh);
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
</style>
