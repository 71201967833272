<template>
  <b-modal
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="true"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="flex-total">
        <img style="height: 60px" src="https://santodomingocartagena.com/img/logo_santo_domingo.png" alt="logo Santo Domingo Cartagena"/>
      </div>
      <div class="pointer justify-content-end">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <b-col class="text-center">
      <h3 id="header-contact">Contacto</h3>
      <b-row class="mt-5">
        <b-col>
          <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="telephone" /></b-row>
          <b-row class="mt-2"><a href="tel:+34968504759" class="text-center">968504759</a></b-row>
        </b-col>
        <b-col>
            <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="envelope" /></b-row>
            <b-row class="mt-2"><a href="mailto:santodomingodecartagena@gmail.com" class="text-center">santodomingodecartagena@gmail.com</a></b-row>
        </b-col>
        <b-col>
            <b-row><b-icon style="color: green" class="icons h1 mt-5" icon="map" /></b-row>
            <b-row class="mt-2"><p class="text-center"> C/Mayor, 28 - 30201, Cartagena, España</p></b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-modal>
</template>

<script lang="ts">
// import Vue from 'vue';
import closeicon from "../assets/components/close.vue";

export default {
  name: "massTime",

  components: {
    closeicon,
  },

  data() {
    return {
      checkState: false,
      phoneContact: undefined,
      emailContac: undefined,
    };
  },

  props: ["modalId"],

  computed: {},
  methods: {
    checkStateChange() {
      this.checkState = !this.checkState;
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
#header-contact {
  border-bottom: 1px solid;
}
</style>
