<template>
  <div id="editor">
    <vue-editor
      v-if="true"
      id="editor"
      useCustomImageHandler
      @image-added="handleImageAdded"
      v-model="htmlForEditor"
    >
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
// import VueCookies from 'vue-cookies';
export default {
  components: {
    VueEditor,
    // VueCookies
  },

  data() {
    return {
      htmlForEditor: "",
      imageDirection: undefined,
      getData: false,
    };
  },
  computed: {
    getImageDirection() {
      // return require("@/assets/img/imageGalery/" + this.imageDirection);
      return "./img/minGallery/" + this.imageDirection;
    },
  },
  watch: {},
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      axios({
        method: "post",
        url: "https://santodomingocartagena.com/apisd/galeria?imageportada",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          token: this.$cookies.get("user_sessionSD"),
          formData,
        },
      })
        .then((result) => {
          console.log(result);
          let res = result.data.replace("null", "");
          const url = JSON.parse(res); // Get url from response
          this.imageDirection = url.image;
          Editor.insertEmbed(cursorLocation, "image", this.getImageDirection);
          console.log(this.htmlForEditor);
          resetUploader();
        })
        .catch((value) => {
          console.log(value);
        });


    },
  },
};
</script>
<style>

</style>
