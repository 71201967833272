<template>
  <div class="content-nav">
    <div id="margin-nav" />
    <b-navbar toggleable="lg" id="navbar">
      <b-navbar-brand class="row align-items-center" href="/">
        <img
          id="header-logo"
          :src="'https://santodomingocartagena.com/img/logo_santo_domingo.png'"
          alt="logo santo domingo cartagena"
          style="height:auto;"
        />
      </b-navbar-brand>
      <b-navbar-toggle
        @click="changeMenu()"
        class="justify-content-end"
        target="nav-collapse"
      ></b-navbar-toggle>
      <b-collapse class="justify-content-end" id="nav-collapse" is-nav>
        <b-navbar-nav class="justify-content-between danger">
          <b-nav-item to="/">Inicio</b-nav-item>
          <b-nav-item @click="$bvModal.show('massTime')"
            >Horario de Misas</b-nav-item
          >
          <b-nav-item to="noticias">Noticias</b-nav-item>
          <b-nav-item to="media">Multimedia</b-nav-item>
          <b-nav-item @click="$bvModal.show('contact')">Contacto</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <massTime modalId="massTime" />
    <contact modalId="contact" />
    <evangelio-dia />
  </div>
</template>

<script lang="ts">
// import Vue from "vue";
import massTime from "./massTime.vue";
import contact from "./contactModal.vue";
import evangelioDia from "../../views/sitioWeb/modals/EvangelioDia.vue";

export default {
  components: {
    massTime,
    contact,
    evangelioDia,
  },
  name: "cabecera",
  props: [],
  data() {
    return {};
  },
  computed: {},

  methods: {
    changeMenu() {
      if (document.getElementById("nav-collapse").style.display == "none") {
        document.getElementById("nav-collapse").style.background = "honeydew";
      } else {
        document.getElementById("nav-collapse").style.background = "";
        document.getElementById("nav-collapse").style.transition = "1s";
      }
    },
  },
};
</script>

<style>
/* arreglar esto */
.nav-link{
font-size: 20px;
}
.justify-content-end {
  margin-right: 80px;
}
#navbar {
  margin: auto;
  color: black;
  transition: 0.6s;
}
.content-nav {
  width: 100%;
  z-index: 1;
}
@media screen and (min-width: 580px) {
  #margin-nav {
  background: honeydew;
  width: 100%;
  height: 100px;
  position: absolute;
  transition: 0.6s;
  opacity: 0.3;
}
  #header-logo {
    width: 350px;
    margin-left: 80px;
    transition: 0.6s;
  }
  
}
@media screen and (max-width: 580px) {
  #margin-nav {
  background: honeydew;
  width: 100%;
  height: 65px;
  position: absolute;
  transition: 0.6s;
  opacity: 1;
}
  #header-logo {
    width: 200px;
    margin-left: 35px;
    transition: 0.6s;
  }

  .justify-content-end {
    margin-right: 0px;
    transition: 0.4s;
  }

  #navbar-nav {
    float: none;
    display: block;
    transition: 0.6s;
    /* text-align: left; */
  }
}
</style>
