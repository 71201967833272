import { render, staticRenderFns } from "./Mantenimiento.vue?vue&type=template&id=560eb8f8&scoped=true&"
import script from "./Mantenimiento.vue?vue&type=script&lang=js&"
export * from "./Mantenimiento.vue?vue&type=script&lang=js&"
import style0 from "./Mantenimiento.vue?vue&type=style&index=0&id=560eb8f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560eb8f8",
  null
  
)

export default component.exports