<template>
  <div ref="parent" :style="`width: ${width}px; height: ${height}px; vertical-align:middle; display:inline-block;`">
    <img ref="image" :src="src" :width="width" :height="height" @load="onImageLoad" />
    <vue-resizable
      v-if="imageLoaded"
      ref="resizable"
      :left="x"
      :top="-height"
      :width="width"
      :height="height"
      @mount="mountResizableElement"
      @resize:end="eHandler"
      :active="['rb', 'lb', 'lt', 'rt']"
      onMouseOver="this.style.borderWidth='3px'"
      onMouseOut="this.style.borderWidth='0px'"
    >
    </vue-resizable>
  </div>
</template>
<script>
import VueResizable from 'vue-resizable';
export default {
  props: ['node', 'updateAttrs', 'view', 'selected', 'getPos', 'options'],
  components: {
    VueResizable,
  },
  data() {
    return {
      mounted: false,
      refreshCounter: 0,
      imageLoaded: false,
    };
  },
  computed: {
    src: {
      get() {
        return this.node.attrs.src;
      },
      set(src) {
        this.updateAttrs({ src });
      },
    },
    width: {
      get() {
        return this.node.attrs.width;
      },
      set(width) {
        this.updateAttrs({
          width: width,
        });
      },
    },
    height: {
      get() {
        return Number(this.node.attrs.height);
      },
      set(height) {
        this.updateAttrs({
          height: height,
        });
      },
    },
    x() {
      if (this.mounted) {
        return this.$refs.image.clientLeft;
      } else return 0;
    },
    y() {
      if (this.mounted) {
        return this.$refs.image.clientTop;
      } else return 0;
    },
  },
  updated() {},
  mounted() {
    this.mounted = true;
  },
  methods: {
    onImageLoad() {
      if (!this.height) this.height = this.$refs.image.height;
      if (!this.width) this.width = this.$refs.image.width;
      this.$refs.parent.style.cssText = `width: ${this.width}px; height: ${this.height}px; vertical-align:middle; display:inline-block;`;
      this.$nextTick(()=>{this.imageLoaded = true;})
    },
    mountResizableElement(data) {
      this.$nextTick(() => {
        this.$refs.resizable.left = this.x;
        this.$refs.resizable.top = -this.height;
        this.$refs.resizable.width = this.width;
        this.$refs.resizable.height = this.height;
      });
    },
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.$refs.resizable.l = this.x;
    },
  },
};
</script>
