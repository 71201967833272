<template>
  <b-modal
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="true"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="flex-total">
        <img
          style="height: 60px"
          src="https://santodomingocartagena.com/img/logo_santo_domingo.png"
          alt="logo santo domingo cartagena"
        />
      </div>
      <div class="pointer justify-content-end">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div>
      <b-tabs content-class="mt-3" justified v-if="horarios.length>0">
        <b-tab :title="horario.titulo" :active="horario.activo" :id="horario.id" v-for="(horario, index) in horarios" :key="index">
          <div v-for="(value, index2) in horario.horarios" :key="index2">
            <h3>{{ Object.keys(value)[0] }}</h3>
            <p>{{ Object.values(value)[0] }}</p>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script lang="ts">
// import Vue from 'vue';
import closeicon from "../assets/components/close.vue";
import axios from "axios";

export default {
  name: "massTime",

  components: {
    closeicon,
  },

  data() {
    return {
      checkState: false,
      phoneContact: undefined,
      emailContac: undefined,
      baseApi: location.protocol + "//" + location.hostname,
      horarios:[],
    };
  },

  props: ["modalId"],
  mounted(){
    this.obtenerHorarios();
  },
  computed: {},
  methods: {
    obtenerHorarios(){
      axios({
        method: "GET",
        url: `${this.baseApi}/apisd/horarios`,
        headers:{"Content-Type": "application/json"},
      })
        .then((data) => {
          this.horarios = Object.values(data.data);
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    checkStateChange() {
      this.checkState = !this.checkState;
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
</style>
