<template>
  <b-row class="p-2">
    <b-col>
      <editor-menu-bar :editor="editor" v-slot="{ commands }">
        <b-row class="text-editor-menu">
          <div class="d-flex">
            <!-- volver atras -->
            <b-button class="button-editor-text" @click="commands.undo">
              <undo-icon />
            </b-button>
            <!-- volver a delante -->
            <b-button class="button-editor-text" @click="commands.redo">
              <redo-icon />
            </b-button>
            <b-button class="mr-1 button-editor-text" @click="commands.bold">
              <span class="bold">N</span>
            </b-button>
            <b-button class="button-editor-text" @click="commands.italic">
              <i>k</i>
            </b-button>
            <b-button
              class="ml-1 button-editor-text"
              @click="commands.underline"
            >
              <u>s</u>
            </b-button>
            <b-button @click="commands.paragraph({ textAlign: 'left' })">
              <!-- <justify-left /> -->
            </b-button>
            <b-button icon @click="commands.paragraph({ textAlign: 'center' })">
              <span>
                <!-- <justify-center /> -->
              </span>
            </b-button>
            <b-button icon @click="commands.paragraph({ textAlign: 'right' })">
              <!-- <justify-right /> -->
            </b-button>
            <b-form-select
              class="custom-fields-selector mx-1"
              v-model="selectedFont"
              @change="fontSelected"
              :options="fontSizes"
            />
            <b-form-select
              class="custom-fields-selector mx-1"
              v-model="selectedFamily"
              @change="fontFamilySelected"
              :options="fontFamilies"
            />
            <b-dropdown
              @click="colorSelected(selectedColor)"
              size="lg"
              variant="link"
              class="select-button-editor-text"
              menu-class="custom-color-menu"
              split
            >
              <template v-slot:button-content>
                <div
                  :style="`color: ${selectedColor}; border-bottom: 3px solid ${selectedColor};`"
                >
                  <format-color-text-icon />
                </div>
              </template>
              <b-dropdown-item
                v-for="(color, index) in colors"
                :key="index"
                @click="colorSelected(color)"
                class="custom-color-selector"
                :style="`background-color: ${color};`"
              />
            </b-dropdown>
            <b-dropdown
              @click="backgroundSelected(selectedBackground)"
              size="lg"
              variant="link"
              class="select-button-editor-text"
              menu-class="custom-color-menu"
              split
            >
              <template v-slot:button-content>
                <div
                  :style="`color: ${selectedBackground}; border-bottom: 3px solid ${selectedBackground};`"
                >
                  <format-color-fill-icon />
                </div>
              </template>
              <b-dropdown-item
                v-for="(color, index) in colors"
                :key="index"
                @click="backgroundSelected(color)"
                class="custom-color-selector"
                :style="`background-color: ${color};`"
              />
            </b-dropdown>
            <button @click="$bvModal.show('selectorImagenEditor')">
              <b-icon icon="card-image" aria-hidden="true"></b-icon>
            </button>
          </div>
        </b-row>
      </editor-menu-bar>
      <selectorImagenes id="selectorImagenEditor"  @imagen="imageSelected" />
      <editor-content
        class="editor__content"
        :editor="editor"
        :disabled="false"
      />
    </b-col>
  </b-row>
</template>
<script lang="ts">
// import Vue from 'vue';
import UndoIcon from "vue-material-design-icons/Undo.vue";
import RedoIcon from "vue-material-design-icons/Redo.vue";
import FormatColorTextIcon from "vue-material-design-icons/FormatColorText.vue";
import FormatColorFillIcon from "vue-material-design-icons/FormatColorFill.vue";
import SelectorImagenes from "./SelectorImages.vue";

import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  History,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Focus,
  Bold,
  Italic,
  Underline,
} from "tiptap-extensions";

import Field from "../tiptapComponents/Field.js";
import FontSize from "../tiptapComponents/FontSize.js";
import FontFamily from "../tiptapComponents/FontFamily.js";
import FieldsList from "../tiptapComponents/FieldsList.js";
import BackgroundColor from "../tiptapComponents/BackgroundColor.js";
import TextColor from "../tiptapComponents/TextColor.js";
import Paragraph from "../tiptapComponents/Paragraph.js";
import TipTapCustomImage from "../tiptapComponents/TipTapImage.js";


export default {
  name: "editorDeTexto",

  components: {
    SelectorImagenes,
    EditorContent,
    EditorMenuBar,
    UndoIcon,
    RedoIcon,
    FormatColorTextIcon,
    FormatColorFillIcon,
  },
  data() {
    return {
      htmlContent: '',
      selectedFont: null,
      previousFont: null,
      selectedFamily: null,
      previousFontFamily: null,
      selectedImages: null,
      selectedBackground: "rgb(255, 255, 255)",
      selectedColor: "rgb(0, 0, 0)",
      fontSizes: [
        { text: "font sizes", value: null },
        { text: "8px", value: "8px" },
        { text: "10px", value: "10px" },
        { text: "12px", value: "12px" },
        { text: "14px", value: "14px" },
        { text: "16px", value: "16px" },
        { text: "18px", value: "18px" },
        { text: "24px", value: "24px" },
        { text: "36px", value: "36px" },
      ],
      fontFamilies: [
        { text: "font families", value: null },
        { text: "Andale Mono", value: "andale mono,times" },
        { text: "Arial", value: "arial,helvetica,sans-serif" },
        { text: "Arial Black", value: "arial black,avant garde" },
        { text: "Book Antiqua", value: "book antiqua,palatino" },
        { text: "Comic Sans MS", value: "comic sans ms,sans-serif" },
        { text: "Courier New", value: "courier new,courier" },
        { text: "Georgia", value: "georgia,palatino" },
        { text: "Helvetica", value: "helvetica" },
        { text: "Impact", value: "impact,chicago" },
        { text: "Symbol", value: "symbol" },
        { text: "Tahoma", value: "tahoma,arial,helvetica,sans-serif" },
        { text: "Terminal", value: "terminal,monaco" },
        { text: "Times New Roman", value: "times new roman,times" },
        { text: "Trebuchet MS", value: "trebuchet ms,geneva" },
        { text: "Verdana", value: "verdana,geneva" },
        { text: "Webdings", value: "webdings" },
        { text: "Wingdings", value: "wingdings,zapf dingbats" },
      ],
      colors: [
        "rgb(0, 0, 0)",
        "rgb(153, 51, 0)",
        "rgb(51, 51, 0)",
        "rgb(51, 51, 0)",
        "rgb(0, 51, 0)",
        "rgb(0, 51, 102)",
        "rgb(0, 0, 128)",
        "rgb(51, 51, 153)",
        "rgb(51, 51, 51)",
        "rgb(128, 0, 0)",
        "rgb(255, 102, 0)",
        "rgb(128, 128, 0)",
        "rgb(0, 128, 0)",
        "rgb(0, 128, 128)",
        "rgb(0, 0, 255)",
        "rgb(102, 102, 153)",
        "rgb(128, 128, 128)",
        "rgb(255, 0, 0)",
        "rgb(255, 153, 0)",
        "rgb(153, 204, 0)",
        "rgb(51, 153, 102)",
        "rgb(51, 204, 204)",
        "rgb(51, 102, 255)",
        "rgb(128, 0, 128)",
        "rgb(153, 153, 153)",
        "rgb(255, 0, 255)",
        "rgb(255, 204, 0)",
        "rgb(255, 255, 0)",
        "rgb(0, 255, 0)",
        "rgb(0, 255, 255)",
        "rgb(0, 204, 255)",
        "rgb(153, 51, 102)",
        "rgb(255, 255, 255)",
        "rgb(255, 153, 204)",
        "rgb(255, 204, 153)",
        "rgb(255, 255, 153)",
        "rgb(204, 255, 204)",
        "rgb(204, 255, 255)",
        "rgb(153, 204, 255)",
        "rgb(204, 153, 255)",
      ],
      editor: new Editor({
        extensions: [
          new History(),
          new Table(),
          new TableCell(),
          new TableHeader(),
          new TableRow(),
          new Field(),
          new Focus({
            className: 'has-focus',
            nested: false,
          }),
          new Bold(),
          new Italic(),
          new Underline(),
          new FontSize(),
          new FontFamily(),
          new FieldsList(),
          new BackgroundColor(),
          new TextColor(),
          new TipTapCustomImage(),
          new Paragraph(),
        ],
        autoFocus: true,
        onUpdate: ({ getHTML }) => {
          this.htmlContent = getHTML();
        },
        content: '<p></p>',
      }),
    };
  },
  props: ["htmlForEditor", "obtenerCambiosHtml"],
  mounted() {
    if (this.htmlForEditor) {
      this.htmlContent = this.htmlForEditor;
      this.editor.setContent(this.htmlContent, true);
    }
  },
  computed: {
    images() {
      let options = this.$store.getters.getAllImages;
      return options;
    },
  },
  watch: {
    obtenerCambiosHtml(){
      this.$emit("nuevoHtml", this.htmlContent);
    },
    htmlContent(value){
      console.log(value);
    },
  },
  methods: {
    imageSelected(value) {
      let img = new Image();
      img.src = "https://santodomingocartagena.com/img/gallery/" + value.urlImage;
      img.alt = value.urlImage;
      img.className = "picture";
      img.onload = () => {
        this.editor.focus();
        this.$nextTick(() => {
          this.editor.commands.image({
            src: "https://santodomingocartagena.com/img/gallery/" + value.urlImage,
            title: value.title,
            height: img.height,
            width: img.width,
          });
        });
      };
    },
    fontFamilySelected(value) {
      this.editor.focus();
      if (this.previousFontFamily !== null)
        this.editor.commands.fontFamily({
          fontFamily: this.previousFontFamily,
        });
      this.$nextTick(() => {
        this.editor.commands.fontFamily({ fontFamily: value });
        this.previousFontFamily = value;
      });
    },
    fontSelected(value) {
      this.editor.focus();
      if (this.previousFont !== null)
        this.editor.commands.fontSize({ fontSize: this.previousFont });
      this.$nextTick(() => {
        this.editor.commands.fontSize({ fontSize: value });
        this.previousFont = value;
      });
    },
    backgroundSelected(val) {
      if (val) {
        this.selectedBackground = val;
        this.editor.focus();
        if (this.activeBackground === this.selectedBackground) {
          this.$nextTick(() => {
            this.editor.commands.backgroundColor({
              backgroundColor: this.selectedBackground,
            });
            this.activeBackground = undefined;
            this.selectedBackground = "rgb(255, 255, 255)";
          });
        } else {
          if (this.activeBackground !== undefined) {
            this.editor.commands.backgroundColor({
              backgroundColor: this.activeBackground,
            });
          }

          this.selectedBackground = val;

          this.$nextTick(() => {
            this.editor.commands.backgroundColor({
              backgroundColor: this.selectedBackground,
            });
            this.activeBackground = val;
          });
        }
      }
    },
    colorSelected(val) {
      if (val) {
        this.selectedColor = val;
        this.editor.focus();
        if (this.activeColor === this.selectedColor) {
          this.$nextTick(() => {
            this.editor.commands.textColor({ textColor: this.selectedColor });
            this.activeColor = undefined;
            this.selectedColor = "rgb(0, 0, 0)";
          });
        } else {
          if (this.activeColor !== undefined) {
            this.editor.commands.textColor({ textColor: this.activeColor });
          }

          this.selectedColor = val;

          this.$nextTick(() => {
            this.editor.commands.textColor({ textColor: this.selectedColor });
            this.activeColor = val;
          });
        }
      }
    },
  },
};
</script>
<style scoped>
li a img {
  width: inherit;
}
</style>
<style>
*, *::before, *::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}
.text-editor-menu .select-button-editor-text {
  border-radius: 16px;
  border: 1px solid #777777 !important;
  border-color: transparent !important;
}
.text-editor-menu .select-button-editor-text:hover {
  border: 1px solid #777777 !important;
}
.text-editor-menu button.btn-link:hover {
  color: #b7b7b7;
}
.text-editor-menu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border: 1px solid #bbbcbd;
  border-radius: 16px 16px 0 0;
  border-bottom: 0;
  padding: 8px;
}
.ProseMirror {
  height: 350px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #bbbcbd;
  border-radius: 0 0 16px 16px;
  padding: 8px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
.text-editor-menu button {
  background: transparent;
  color: #b7b7b7;
  border-radius: 16px;
}
.custom-color-menu {
  flex-wrap: wrap;
  min-width: 152px;
  width: 152px;
  max-width: 152px;
}
.btn-group,
btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
@media screen and (min-width: 990px) {
  .headerEditor {
    display: flex;
  }
}
</style>
