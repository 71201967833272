<template>
  <div id="loadingwebsite">
    <img animation: class="loadingLogo" alt="logo santo domingo cartagena"
    :src="'https://santodomingocartagena.com/img/logo_santo_domingo.png'"/>
  </div>
</template>

<script lang="ts">
export default {
  components: {},
  name: "loadingWebSite",
  props: [],
  data() {
    return {};
  },
  computed: {},

  methods: {},
};
</script>

<style>
body{
    height: 100%;
    width: 100%;
}
.loadingLogo {
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  margin: 0 auto;
  animation: mymove 2s infinite;
}

@keyframes mymove {
  50% {
    transform: scale(0.5);
  }
}
</style>
