<template>
  <b-col>
    <div>
      <galeryPhoto id="galeryPhoto" />
    </div>
    <div class="sectionCard">
      <cardsHome />
      <img
        class="iconScroll"
        :src="'https://santodomingocartagena.com/img/icon/scroll-down-icon.gif'"
        alt=""
      />
    </div>
    <div class="historyParr">
      <!-- <h2>Historia</h2> -->
      <p>El templo de Santo Domingo y convento dominico al que pertenecía se empezó a edificar en el año 1580, siendo uno de los templos más 
        antiguos de Cartagena con culto ininterrumpido. Contó desde un principio con el cariño y el apoyo del pueblo y cabildo de Cartagena por la gran escasez de clérigos
      que había en ese momento en la ciudad y campo de Cartagena. Con la desamortización, fueros expulsados los frailes. En 1865 pasa a ser Parroquia Castrense, pero, a poco, 
      se desplomo gran parte del edificio y el Ministerio de Marina la reedifica entre 1875-1880. En 1933 desaparece la Jurisdicción Castrense y vuelve a remodelarse en 1958 
      para servir de Parroquia Castrense</p>
    </div>
    <div class="bodyPrincipalPage">
      <div class="doble-columnas">
        <b-col>
          <b-card class="enlaces">
            <h2 id="titleLastNews">Ultimas Noticias</h2>
            <div v-if="noticiasCargadas" class="lastNewsPp">
              <lastNews
                v-for="(item, index) in news.slice(0, width)"
                :imageSrc="item.imagePortada"
                :newTitle="item.titulo"
                :cuerpoNoticia="item.cuerpoNoticia"
                :fechaPublicacion="item.fechaPublicacion"
                :key="index"
                :idnoticia="item.id"
                :idGallery="item.idGallery"
                @getIdNews="getId"
              />
            </div>
            <div v-else>
              <b-spinner></b-spinner>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="enlaces">
            <h2 id="titleLastNews">Enlaces de interes</h2>
            <div v-if="noticiasCargadas" class="">
              <enlaces
                v-for="(item, index) in enlacesInteres.slice(0, 4)"
                
                :titulo="item.titulo"
                :descripcion="item.descripcion"
                :autor="item.autor"
                :url="item.url"
                :key="index"
                @getIdNews="getId"
              />
            </div>
          </b-card>
        </b-col>
      </div>
      <b-card class="d-flex">
        <div class="d-flex">
          <iframe
            id="iframe-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.0555838851574!2d-0.988664885195821!3d37.600850829920915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd634228cc2985b5%3A0x96b140c99ff6040c!2sIglesia%20Castrense%20de%20Santo%20Domingo!5e0!3m2!1ses!2ses!4v1665144548613!5m2!1ses!2ses"
            width="75%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
          <b-card class="w-25 location-card">
            <div class="mt-3">
              <div>
                <img
                  style="width: 55px"
                  :src="'https://santodomingocartagena.com/img/icon/locationIglesia.svg'"
                  alt=""
                />
              </div>
              <div class="">
                <h5>Calle mayor, 28</h5>
                <h5>30201 Cartagena</h5>
              </div>
            </div>
          </b-card>
        </div>
      </b-card>
    </div>
  </b-col>
</template>

<script>
import galeryPhoto from "../../src/components/galery/galeryPhoto.vue";
import cardsHome from "../../src/components/cardsPrincipalMenu.vue";
import lastNews from "../../src/components/news/lastNews.vue";
import axios from "axios";
import enlaces from "../../src/components/Enlaces.vue"

export default {
  data() {
    return {
      width: 6,
      news: [],
      baseApi: location.protocol + "//" + location.hostname,
      enlacesInteres:[],
    };
  },
  metaInfo() {
    return {
      title: "Parroquia Castrense Santo Domingo de Cartagena",
      meta: [
        {
          name: "description",
          content:
            "Informacion sobre la Parroquia Castrense de Santo Domingo Cartagena.",
        },
        {
          name: "keywords",
          content:
            "santo domingo cartagena, iglesia santo domingo cartagena, parroquia santo domingo cartagena, parroquia castrense de santo domingo, misas cartagena, iglesia castrense de santo domingo, misas santo domingo cartagena",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://santodomingocartagena.com/",
        },
      ],
    };
  },
  name: "home",
  props: {
    msg: String,
  },
  components: {
    galeryPhoto,
    cardsHome,
    lastNews,
    enlaces,
  },
  computed: {
    noticiasCargadas() {
      return this.news.length > 0 ? true : false;
    },
  },
  methods: {
    pintaUrls(){
      axios({
        method: "GET",
        url: `${this.baseApi}/apisd/enlacesinteres?all=active`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          if(data.data.datos.length>0){
            data.data.datos.forEach(element => {
              this.montarSitios(element);            });
          }
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    montarSitios(element){
      // var imagen = Object.getOwnPropertyNames(element).find((list)=>list.includes('image'));
      var title = Object.getOwnPropertyNames(element).find((list)=>list.includes('title'));
      var description = Object.getOwnPropertyNames(element).find((list)=>list.includes('description'));
      var author = Object.getOwnPropertyNames(element).find((list)=>list.includes('author'));
      this.enlacesInteres.push({
        // imagen:Object.entries(element).filter((elemento)=>elemento[0]==imagen)[0][1],
        titulo:Object.entries(element).filter((elemento)=>elemento[0]==title).length > 0 ? Object.entries(element).filter((elemento)=>elemento[0]==title)[0][1] : '',
        descripcion:Object.entries(element).filter((elemento)=>elemento[0]==description).length > 0 ? Object.entries(element).filter((elemento)=>elemento[0]==description)[0][1]: '',
        autor:Object.entries(element).filter((elemento)=>elemento[0]==author).length > 0 ? Object.entries(element).filter((elemento)=>elemento[0]==author)[0][1] : '',
        url:element.url
      })

    },
    onResize() {
      if (
        (window.innerWidth >= 600 && window.innerWidth < 950) ||
        (window.innerWidth >= 950 && window.innerWidth < 1200)
      ) {
        this.width = 6;
      } else if (window.innerWidth >= 1200 && window.innerWidth <= 1500) {
        this.width = 6;
      } else if (window.innerWidth < 600){
        this.width = 4;
      }else {
        this.width = 8;
      }
    },
    obtenerNoticias() {
      axios({
        method: "GET",
        url: `${this.baseApi}/apisd/noticias`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          this.news = data.data;
          this.news = this.news.reverse();
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    getId(value) {
      let title = value.title.replaceAll(" ", "_");
      this.$router.push({
        name: "noticia",
        query: { id: value.id, title: title },
      });
    },
  },
  mounted() {
    this.obtenerNoticias();
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if(window.location.search == '?horario')this.$bvModal.show('massTime');
    this.pintaUrls();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.enlaces{
  min-height: 97.5%;
  background: rgb(254, 255, 254, 0.2);
  margin: 15px;
}
.location-card {
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://santodomingocartagena.com/img/gallery/altar_virgen.jpg");
  background-position: center;
}
p {
  max-width: 1200px;
  display: inline-flex;
  font-style: italic;
  color: black;
}
.lastNewsPp {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  transition: 2s;
}
.historyParr {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  background: rgba(240, 255, 240, 0.8);
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
}
.iconScroll {
  filter: brightness(1.1);
  mix-blend-mode: multiply;
  position: absolute;
  bottom: 0;
  width: 100px;
  margin-left: 48%;
  left: 0;
}
.sectionCard {
  height: calc(100vh);
  position: relative;
}
#galeryPhoto {
  z-index: -1;
  position: fixed;
  width: 100%;
}
.lastNews {
  height: 100%;
  padding-top: 15px;
}
.cardsHome {
  height: 650px;
}
#titleLastNews {
  border-bottom: 1px solid;
  text-align: initial;
}
.bodyPrincipalPage {
  background: rgb(254, 255, 254, 0.7);
}
@media (max-width: 990px) {
  .doble-columnas{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  transition: 2s;
}
}
@media (min-width: 990px) {
  .doble-columnas{
  display: grid;
  grid-template-columns: 1fr 350px;
  transition: 2s;
}
}
@media (max-width: 600px) {
  .iconScroll {
    display: none;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .location-card {
    display: none;
  }
  #iframe-map {
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 950px) {
  .iconScroll {
    display: none;
  }
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
