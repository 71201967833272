import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import VueCookies from "vue-cookies";
import { routes } from "./routes.js";
import Meta from "vue-meta";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import * as VueAos from "vue-aos";
import store from "./store";
// import urlMetadata from "url-metadata"

Vue.use(VueAos);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
// Vue.use(require('vue-cookies'));
Vue.use(VueCookies);
Vue.use(Meta);
Vue.use(VueSidebarMenu);
// Vue.use(urlMetadata);

const router = new VueRouter({ mode: "history", routes });
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-WT5KRVZTJQ");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.onscroll = function () {
  scrollFunction();
};
window.onload = function () {
  sizeWindow();
  scrollFunction();
};
window.onresize = function () {
  sizeWindow();
};
function scrollFunction() {
  if (
    document.body.scrollTop > 100 ||
    document.documentElement.scrollTop > 100 ||
    window.innerWidth <= 990
  ) {
    if (document.getElementById("margin-nav")) {
      document.getElementById("navbar").style.padding = "0px 0px";
      document.getElementById("header-logo").style.width = "200px";
      document.getElementById("margin-nav").style.height = "65px";
      document.getElementById("margin-nav").style.opacity = "1";
      if (document.getElementsByClassName("nav-link")) {
        let navLink = document.getElementsByClassName("nav-link");
        for(let a = 0; a<navLink.length; a++){
          navLink[a].style.color = "rgba(0, 0, 0, 0.55)";
        }
      }
    }
  } else {
    if (document.getElementById("margin-nav")) {
      document.getElementById("header-logo").style.width = "350px";
      document.getElementById("margin-nav").style.height = "100px";
      document.getElementById("margin-nav").style.opacity = "0.3";
      // if (document.getElementsByClassName("nav-link")) {
      //   let navLink = document.getElementsByClassName("nav-link");
      //   for(let a = 0; a<navLink.length; a++){
      //     navLink[a].style.color = "White";
      //   }
      // }
    }
  }
}
function sizeWindow() {
  if (document.getElementById("margin-nav")) {
    if (window.innerWidth <= 990) {
      document.getElementById("header-logo").style.width = "200px";
      document.getElementById("margin-nav").style.height = "65px";
      document.getElementById("margin-nav").style.opacity = "1";
    } else {
      document.getElementById("header-logo").style.width = "350px";
      document.getElementById("margin-nav").style.height = "100px";
      document.getElementById("margin-nav").style.opacity = "0.3";
    }
  }
}
