<template>
  <b-modal
    scrollable
    class="popupNews"
    size="xl"
    :id="modalId"
    :hide-footer="false"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="flex-total">
        <h2>{{ noticia ? "Editar Noticia" : "Nueva Noticia" }}</h2>
      </div>
      <div class="pointer justify-content-end">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div id="editor">
      <div class="headerEditor">
        <div style="margin: 10px">
          <h5>Título:</h5>
          <input type="text" v-model="titleNews" />
        </div>
        <div style="margin: 10px">
          <h5>Fecha de la Noticia:</h5>
          <b-form-datepicker
            locale="es"
            id="fechaNoticia"
            v-model="fechaNoticia"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div style="margin: 10px">
          <div>
            <h5>¿Vinculada a una galeria?:</h5>
            <b-form-checkbox
              id="checkbox-1"
              v-model="haveGallery"
              name="checkbox-1"
            >
              <p style="margin-left: 5px">{{ haveGallery ? " SI" : " NO" }}</p>
            </b-form-checkbox>
          </div>
          <div>
            <b-form-select
              v-if="haveGallery"
              v-model="gallerySelected"
              :options="galleryOptions"
            ></b-form-select>
          </div>
        </div>
        <div class="m-2">
          <div class="cardViewconfconf">
            <div id="imgChangeImage" class="hoverr-2 text-white rounded">
              <img
                @click="$bvModal.show('crearNoticia')"
                style="height: 150px"
                :src="prefill"
              />
            </div>
          </div>
        </div>
      </div>
      <input
        type="text"
        v-model="cuerpoNoticia"
        placeholder="Introducción de la Noticia"
      />
      <editor-de-texto
        :htmlForEditor="htmlForEditor"
        :obtenerCambiosHtml="obtenerCambiosHtml"
        @nuevoHtml="nuevoHtml"
      ></editor-de-texto>
    </div>
    <keep-alive>
      <selectorImagenes @imagen="remplaceImage" id="crearNoticia" />
    </keep-alive>
    <template v-slot:modal-footer>
      <div class="pointer justify-content-end">
        <b-button variant="success" @click="saveNews">Guardar</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
// import Vue from 'vue';
import editorDeTexto from "./EditorDeTexto.vue";
import closeicon from "../../assets/components/close.vue";
import axios from "axios";
import selectorImagenes from "./SelectorImages.vue";
// import pictureInput from "vue-picture-input";

export default {
  name: "editorDeNoticias",

  components: {
    editorDeTexto,
    closeicon,
    selectorImagenes,
  },
  data() {
    return {
      obtenerCambiosHtml: true,
      gallerySelected: undefined,
      fechaNoticia: undefined,
      haveGallery: false,
      titleNews: undefined,
      imagePortada: undefined,
      cuerpoNoticia: undefined,
      htmlForEditor: "<div></div>",
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  props: ["modalId", "galerias", "noticia"],
  mounted() {
    this.$store.dispatch("changeAllImages");
    if (this.noticia) {
      this.cuerpoNoticia = this.noticia.cuerpoNoticia;
      this.titleNews = this.noticia.Titulo;
      this.date = this.noticia;
      let day = new Date(this.noticia.FechaDePublicacion).getDate();
      let month = new Date(this.noticia.FechaDePublicacion).getMonth();
      let year = new Date(this.noticia.FechaDePublicacion).getFullYear();
      let fecha = year + "-" + month + "-" + day;
      this.fechaNoticia = fecha;
      if (this.noticia.galeria != 0) {
        this.haveGallery = true;
        this.gallerySelected = this.noticia.galeria;
      } else {
        this.haveGallery = false;
        this.gallerySelected = this.noticia.galeria;
      }
      this.htmlForEditor = this.noticia.Noticia;
      this.imagePortada = this.noticia.imagenDePortada;
    }
  },
  computed: {
    prefill() {
      if (this.imagePortada) {
        return (
          // "/" + require("@/assets/img/minImageGallery/" + this.imagePortada)
          "https://santodomingocartagena.com/img/minGallery/" +
          this.imagePortada
        );
      } else {
        return require("@/assets/img/icon/plus-svgrepo-com.svg");
      }
    },
    galleryOptions() {
      let gallery = [];
      if (this.galerias && this.galerias.length > 0) {
        this.galerias.forEach((element) => {
          gallery.push({ value: element.id, text: element.titulo });
        });
      }
      return gallery;
    },
  },
  watch: {
    gallerySelected: function (value) {
      console.log("galleryselected:" + value);
    },
    fechaNoticia: function (value) {
      console.log(value);
    },
  },
  methods: {
    nuevoHtml(value) {
      this.htmlForEditor = value;
      if (this.noticia) {
        this.actualizarNoticia();
      } else {
        this.createNews();
      }
    },
    createNews() {
      axios({
        method: "post",
        url: `${this.baseApi}/apisd/noticias?crearNoticia`,
        headers: { token: this.$cookies.get("user_sessionSD") },
        data: {
          titulo: this.titleNews,
          cuerpoNoticia: this.cuerpoNoticia ? this.cuerpoNoticia : "",
          htmlText: this.htmlForEditor,
          fechaPublicacion: new Date(this.fechaNoticia),
          imagenPortada: this.imagePortada,
          gallery: this.gallerySelected ? this.gallerySelected : "",
        },
      })
        .then((value) => {
          let okValue = "Noticia guardada Correctamente";
          let notOkValue =
            "Ocurrio un error durante el proceso de actualizacion, revise la noticia.";

          this.$bvModal
            .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            })
            .then(() => {
              this.closeModal();
              this.$emit("changeNews", true);
            });
        })
        .catch((value) => {
          console.log(value);
        });
    },
    remplaceImage(value) {
      this.imagePortada = value.urlImage;
    },
    actualizarNoticia() {
      axios({
        method: "put",
        url: `${this.baseApi}/apisd/noticias?actualizarNoticia`,
        headers: { token: this.$cookies.get("user_sessionSD") },
        data: {
          id: this.noticia && this.noticia.Id ? this.noticia.Id : 0,
          titulo: this.titleNews,
          cuerpoNoticia:
            this.noticia && this.noticia.cuerpoNoticia
              ? this.noticia.cuerpoNoticia
              : "",
          htmlText: this.htmlForEditor,
          fechaPublicacion: new Date(this.fechaNoticia),
          imagenPortada: this.imagePortada,
          gallery: this.gallerySelected,
        },
      })
        .then((value) => {
          let okValue = "Noticia guardada Correctamente";
          let notOkValue =
            "Ocurrio un error durante el proceso de actualizacion, revise la noticia.";

          this.$bvModal
            .msgBoxOk(value.status === 200 ? okValue : notOkValue, {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Aceptar",
            })
            .then(() => {
              this.closeModal();
              this.$emit("changeNews", true);
            });
        })
        .catch((value) => {
          console.log(value);
        });
    },
    saveNews() {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea guardar los cambios?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((chechResponse) => {
          if (chechResponse) {
            this.obtenerCambiosHtml = !this.obtenerCambiosHtml;
          }
        });
    },
    closeModal() {
      this.$emit("closeModal");
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style>
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
.custom-color-menu {
  flex-wrap: wrap;
  min-width: 152px;
  width: 152px;
  max-width: 152px;
}
.btn-group,
btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
@media screen and (min-width: 990px) {
  .headerEditor {
    display: flex;
  }
}
</style>
