<template>
  <div id="galeryPhoto" class="slideshow-container">
    <div v-for="(item, index) in photos" :key="index" class="mySlides fadee">
      <picture>
        <img
          :src="
            'https://santodomingocartagena.com/img/gallery/' + item.urlImage
          "
        />
      </picture>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "galeryPhoto",
  data() {
    return {
      slideIndex: 0,
      slide: 0,
      sliding: null,
      photos: [],
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    axios({
      method: "GET",
      url: `${this.baseApi}/apisd/galeria?id=`+"1",
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => {
        this.photos = data.data;
        this.$nextTick(() => this.showSlides());
      })
      .catch((erro) => {
        console.log(erro);
      });
  },
  watch: {},
  methods: {
    handleScroll() {
      if (document.getElementById("galeryPhoto")) {
        let position = document.documentElement.scrollTop / 80;
        let opaciti = 100 - Math.trunc(position);
        if (position < 25) {
          document.getElementById("galeryPhoto").style.filter =
            "blur(" + position + "px)";
          document.getElementById("galeryPhoto").style.opacity =
            "" + opaciti.toString() + "%";
        }
      }
    },
    showSlides() {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      if (slides.length > 0) {
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
        this.slideIndex++;
        if (this.slideIndex > slides.length) {
          this.slideIndex = 1;
        }
        slides[this.slideIndex - 1].style.display = "block";
        setTimeout(this.showSlides, 8000); // 8000 Change image every 2 seconds
      }
    },
  },
};
</script>
<style scoped>
.imageCarrousel img {
  width: 100px;
}
.mySlides img {
  max-width: 100%;
  object-fit: cover;
  height: 100% !important;
  width: 100% !important;
  vertical-align: middle;

  animation-name: mySlides;
  animation-duration: 50s;
}
.mySlides {
  /* justify-content: center; */
  height: 100vh !important;
  position: relative;
}
/* -------------------------- */
.active {
  background-color: #717171;
}
/* Fading animation */
.fadee {
  animation-name: fadee;
  animation-duration: 3.5s;
}
@keyframes fadee {
  from {
    opacity: 0.4;
    /* scale: 1; */
  }
  to {
    opacity: 1;
    /* scale: 1.5; */
  }
}
@keyframes mySlides {
  from {
    /* opacity: 0.4; */
    scale: 1;
  }
  to {
    /* opacity: 1; */
    scale: 1.5;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}
</style>
