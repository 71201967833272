<template>
  <b-col>
    <b-row>
      <b-button @click="modalEditor()" variant="success">Nueva noticia</b-button>
    </b-row>
    <b-row v-if="isUpload">
      <b-card id="tableNews" class="table-responsive">
        <b-table
          sticky-header
          responsive
          :items="items"
        >
          <template v-slot:cell(Noticia)="data">
            <span v-html="data.value"></span>
          </template>
          <template v-slot:cell(FechaDePublicacion)="data">
            <p>{{ getDate(data.value) }}</p>
          </template>
          <template v-slot:cell(galeria)="data">
            <p>{{ getGallery(data.value) }}</p>
          </template>
          <template v-slot:cell(imagenDePortada)="data">
            <img
              :src="
                'https://santodomingocartagena.com/img/gallery/' + data.value
              "
              height="150px"
            />
          </template>
          <template v-slot:cell(edicionNew)="data">
            <div class="d-flex">
              <b-button
                class="m-1"
                variant="warning"
                @click="modalEditor(data.value)"
                >Editar</b-button
              >
              <b-button
                class="m-1"
                @click="deleteNew(data.value)"
                variant="danger"
                >Eliminar</b-button
              >
            </div>
          </template>
        </b-table>
      </b-card>
    </b-row>
    <b-row v-else>
      <div class="loadingLogo">
        <b-spinner></b-spinner>
      </div>
    </b-row>
    <editorDeNoticias
      v-if="datosCargados"
      @changeNews="changeNews"
      :noticia="getNews"
      :galerias="galerias"
      modalId="editorDeNoticias"
      @closeModal="datosCargados=false"
    />
  </b-col>
</template>

<script>
import axios from "axios";
import editorDeNoticias from "../../src/components/dashboard/EditorDeNoticias.vue";

export default {
  name: "panelNoticias",
  props: [],
  components: {
    editorDeNoticias,
  },
  data() {
    return {
      datosCargados: false,
      items: [],
      galerias: [],
      getNews: undefined,
      isUpload: false,
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    modalEditor(idNoticia) {
      if (idNoticia) {
        this.getNews = this.items.find((element) => element.Id === idNoticia);
      } else {
        this.getNews = undefined;
      }
      this.$nextTick(() => {
        this.datosCargados = true;
        this.$bvModal.show("editorDeNoticias");
      });
    },
    deleteNew(idNew) {
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea eliminar esta noticia?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          console.log("OKCLIC" + idNew);
          if (option) {
            console.log("Mensaje eliminado correctamente");
          }
        });
    },
    getGallery(idGallery) {
      if (this.galerias) {
        let galeria = this.galerias.filter(
          (galeria) => galeria.id === idGallery
        );

        if (galeria.length > 0) {
          return galeria[0].titulo;
        } else {
          return "Galeria No asociada";
        }
      }
    },
    getDate(data) {
      let day = new Date(data).getDate();
      let month = new Date(data).getMonth();
      let year = new Date(data).getFullYear();
      return day + "-" + month + "-" + year;
    },
    changeNews() {
      this.isUpload = false;
      axios
        .get(`${this.baseApi}/apisd/galeria`)
        .then((data) => {
          this.galerias = data.data;
          axios
            .get(`${this.baseApi}/apisd/noticias?totalNoticias`)
            .then((data) => {
              this.items = [];
              data.data.forEach((element) => {
                this.items.push({
                  Id: element.id,
                  Titulo: element.titulo,
                  Noticia: element.htmlText,
                  FechaDePublicacion: element.fechaPublicacion,
                  galeria: element.idGallery,
                  imagenDePortada: element.imagePortada,
                  edicionNew: element.id,
                  cuerpoNoticia: element.cuerpoNoticia,
                });
                this.isUpload = true;
              });
              this.items.reverse();
            })
            .catch((erro) => {
              console.log(erro);
            });
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
  },
  mounted() {
    this.changeNews();
  },
  computed: {},
};
</script>
<style scoped>

.b-table-sticky-header{
  max-height: 100%;
}
#tableNews{
  height: calc(91vh);
}
#tableNews .card-body{
  padding: 0;
  height: 100%;
}
#createNews {
  background: black;
}
.row {
  width: 100%;
  margin: 0;
}
</style>
