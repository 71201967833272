<template>
  <div id="footer" class="footerGroup">
    <b-row>
      <b-col>
        <div>
          <b-row>
            <b-icon class="icons h3 mt-5" icon="telephone" />
          </b-row>
          <b-row class="mt-2">
            <a href="tel:+34968504759" class="text-center">968504759</a>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <b-row
          ><b-icon  class="icons h3 mt-5" icon="envelope"
        /></b-row>
        <b-row class="mt-2"
          ><a
            href="mailto:santodomingodecartagena@gmail.com"
            class="text-center"
            >santodomingodecartagena@gmail.com</a>
          </b-row>
      </b-col>
      <b-col>
        <b-row><b-icon  class="icons h3 mt-5" icon="map" /></b-row>
        <b-row class="mt-2"><p class="text-center"> C/Mayor, 28 - 30201, Cartagena, España</p></b-row>
        </b-col>
    </b-row>
    <b-row>
      <b-col>
        <a target=”_blank” href="https://www.youtube.com/channel/UCfr6z62AIKhH2cJbKOsPRYw/featured">
          <b-icon class="icons youtube-icon h3 mt-5 m-2 p-1" icon="youtube"/>
        </a>
        <a target=”_blank” href="https://www.facebook.com/santodomingo.decartagena.1">
          <b-icon class="icons facebook-icon h3 mt-5 m-2 p-1" icon="facebook"/>
        </a>
        </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "footer-site",
  props: [],
  components: {},
  data() {
    return {
      lista: [],
      title: "",
      onlyImage: [],
      index: null,
    };
  },
  watch: {},
  methods: {},
  mounted: {},
};
</script>
<style scoped>
.row {
  margin: 0;
}
.footerGroup {
  background-color: #444;
  color: antiquewhite;
}
.icon-footer :hover {
  color: blue;
}
.youtube-icon:hover{
color: red;
cursor: pointer;
}
a{
  color: aliceblue;
}
.facebook-icon:hover{
  color:#3b5998;
  cursor: pointer;
}
</style>
