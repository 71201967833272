<template>
  <div id="bodyDashboard">
    <div v-if="lista.length > 0" class="wrapper">
      <!-- <template v-for="(item, index) in lista"> -->
      <cardView
        v-for="(item, index) in lista"
        :imageSrc="item.backImage"
        :imagetitle="item.titulo"
        :imageDescription="item.descripcion"
        :key="index"
        :idGallery="item.id"
        @iconClick="actionGallery"
      />
      <!-- </template> -->
    </div>
    <div class="loadingLogo" v-else>
      <b-spinner></b-spinner>
    </div>
    <editGallery
      :key="gallerySelected"
      :dataGallery="gallerySelected"
      v-if="editGallery"
      @updateGalleries="callGallery()"
    />
    <selectorImagenes :idGallery="idgallery" :multiSelect="true" id="createGallery" />
  </div>
</template>

<script>
import cardView from "../../src/components/dashboard/cardView.vue";
import axios from "axios";
import editGallery from "../../src/components/galery/EditGallery.vue";
import selectorImagenes from "../../src/components/dashboard/SelectorImages.vue";

export default {
  name: "createGallery",
  props: ["listMedia"],
  components: {
    cardView,
    editGallery,
    selectorImagenes,
  },
  data() {
    return {
      lista: [],
      editGallery: false,
      gallerySelected: undefined,
      idgallery: undefined,
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  mounted() {
    this.callGallery();
  },
  methods: {
    callGallery() {
      axios
        .get(`${this.baseApi}/apisd/galeria`)
        .then((data) => {
          this.lista = [];
          this.lista = data.data;
          this.lista = this.lista.reverse();
          this.lista.push({
            backImage: require("@/assets/img/icon/plus-svgrepo-com.svg"),
            titulo: "Nueva Galeria",
            id: "0",
            descripcion: "Crear nueva Galeria",
          });
          this.lista.push({
            backImage: require("@/assets/img/icon/plus-svgrepo-com.svg"),
            titulo: "Carrousel",
            id: "1",
            descripcion: "Carrousel Pagina Principal",
          });
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    actionGallery(value) {
      switch (value.action) {
        case "openGallery":
          this.idgallery = value.id;
          this.$nextTick(() => {
            this.$bvModal.show("createGallery");
          });
          break;
        case "deleteGallery":
          this.deleteGallery(value.id);
          break;
        case "editGallery":
          this.gallerySelected = value;
          this.editGallery = true;
          this.$nextTick(() => {
            this.$bvModal.show("editGallery");
          });
          break;
        case "newGallery":
          this.gallerySelected = value;
          this.editGallery = true;
          this.$nextTick(() => {
            this.$bvModal.show("editGallery");
          });
          break;

        default:
          break;
      }
    },
    deleteGallery(id) {
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar esta Galeria y todas sus fotos?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          if (option) {
            // `${this.baseApi}/apisd/galeria?id=" + id   => trae todas las imagenes
            axios({
              method: "GET",
              url: `${this.baseApi}/apisd/galeria?id=` + id,
            })
              .then((value) => {
                let listImages = [];
                // Obtenemos la url de las imagenes
                for (const iterator of value.data) {
                  listImages.push("./img/gallery/" + iterator.urlImage);
                  listImages.push("./img/minGallery/" + iterator.urlImage);
                  console.log(this.$cookies.get("user_sessionSD"));
                }
                axios({
                  method: "DELETE",
                  url: `${this.baseApi}/apisd/galeria`,
                  headers: { "Content-Type": "application/json" },
                  data: {
                    token: this.$cookies.get("user_sessionSD"),
                    images: listImages,
                    idGallery: id,
                  },
                })
                  .then(() => {
                    this.callGallery();
                  })
                  .catch((value) => {
                    console.log("ERROR");
                    console.log(value);
                  });
              })
              .catch((value) => {
                console.log(value);
              });
          }
        });
    },
  },
  computed: {
    getRoutePath() {
      return this.$router.path;
    },
  },
};
</script>
<style>
.wrapper {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
#bodyDashboard {
  transition: 0.5s;
  padding-top: 10px;
}
@media screen and (max-width: 600px) {
  .wrapper {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>
