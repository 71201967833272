<template>
  <div :key="this.$route.query.id" class="bodyCarrete" v-if="cuerpoNoticia !=''">
    <h1 class="title">{{ title.replaceAll("_", " ") }}</h1>
    <div class="cuerpoNoticia mt-4" v-html="newBody" />
    <div v-if="idGallery && idGallery != 0" class="galleryButton">
      <button class="btn btn-success" @click="goGallery">
        <b-icon icon="images" />Galeria
      </button>
    </div>
    <div class="backButton">
      <img
        :src="'https://santodomingocartagena.com/img/icon/backButton.png'"
        alt="atras"
        @click="$router.go(-1)"
      />
    </div>
  </div>
  <div class="bodyCarrete" id="changeWeb" v-else>
      <loadingWebSite />
    </div>
</template>

<script>
import axios from "axios";
import loadingWebSite from "../../src/components/loadingwebsite.vue";
// import require from "require-vuejs";

export default {
  name: "vistaNoticia",
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://santodomingocartagena.com${this.$route.fullPath}`,
        },
      ],
      title: `Santo Domingo Cartagena - ${this.title.replaceAll("_", " ")}`,
      meta: [
        {
          name: "description",
          content: `${this.cuerpoNoticia}`,
        },
      ],
    };
  },
  props: [],
  components: {
    loadingWebSite,
  },
  data() {
    return {
      newBody: undefined,
      title: "",
      onlyImage: [],
      idGallery: undefined,
      cuerpoNoticia: "",
      imagePortada: "",
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    htmlContentEdit() {
      if (this.newBody.indexOf("<img") >= 0) {
        let indice = this.newBody.indexOf("<img") + 5;
        this.newBody =
          this.newBody.slice(0, indice) +
          ' style="max-width: -webkit-fill-available;" ' +
          this.newBody.slice(indice);
      }
    },
    goGallery() {
      this.$router.push({
        name: "carrete",
        query: { id: this.idGallery, title: this.title },
      });
    },
    getnews() {
      let id = this.$route.query.id;
      this.title = this.$route.query.title;

      axios
        .get(`${this.baseApi}/apisd/noticias?id=` + id)
        .then((data) => {
          this.idGallery = data.data[0].idGallery;
          this.newBody = data.data[0].htmlText;
          this.cuerpoNoticia = data.data[0].cuerpoNoticia;
          this.imagePortada = data.data[0].imagePortada;
          this.htmlContentEdit();
          this.modifyMeta();
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    modifyMeta() {
      document.querySelector('head meta[property="og:title"]').content =
        this.title;
      document.querySelector('head meta[property="og:description"]').content =
        this.cuerpoNoticia;
      document.querySelector('head meta[property="og:url"]').content =
        document.location.href;
    },
  },
  mounted() {
    this.getnews();
  },
};
</script>
<style>
.bodyCarrete {
  padding-top: 120px;
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
.galleryButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0;
}
.cuerpoNoticia {
  margin-left: 84px;
  margin-right: 84px;
}
</style>
