<template>
  <div id="submenu" class="justify-content-center align-item-center">
    <b-card class="card-body" @click="$bvModal.show('massTime')">
      <b-col>
        <b-row id="cards">
          <b-icon class="icons" icon="clock"></b-icon>
          <h5>Horarios</h5>
        </b-row>
      </b-col>
    </b-card>
    <b-card class="card-body" @click="$router.push('noticias')">
      <b-col>
        <b-row id="cards">
          <b-icon class="icons" icon="newspaper"></b-icon>
          <h5>Noticias</h5>
        </b-row>
      </b-col>
    </b-card>
    <b-card class="card-body" @click="$router.push('media')">
      <b-col>
        <b-row id="cards">
          <b-icon class="icons" icon="play-circle"></b-icon>
          <h5>Multimedia</h5>
        </b-row>
      </b-col>
    </b-card>
    <b-card class="card-body" @click="$bvModal.show('evangelioDia')">
      <b-col>
        <b-row id="cards">
          <b-icon class="icons" icon="book"></b-icon>
          <h5>Evangelio del dia</h5>
        </b-row>
      </b-col>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "cardsHome",
  props: {
    msg: String,
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 990px) {
  .row {
    margin: 0;
  }
  #cards {
    width: auto;
  }
  #submenu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    top: 50%;
  }
  .card-body {
    transition: 1s;
  }
}
@media (min-width: 990px) {
  .row {
    margin: 0;
  }
  #cards {
    width: 100%;
  }
  #submenu {
    display: flex;
    position: relative;
    top: 50%;
  }
  .card-body {
    transition: 1s;
    padding: 1rem 1rem;
  }
}

.icons {
  height: 25px;
  color: black;
}
h5 {
  color: black;
}
.card {
  padding: 0px;
  margin: 5px;
  cursor: pointer;
  padding: 0;
  background: rgba(240, 255, 240, 0.7);
}
.row {
  margin: 0;
}

.card :hover {
  transition: 1s;
  background: rgba(240, 255, 240, 1);
}
</style>
