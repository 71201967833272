<template>
  <b-modal
    class="popupNews"
    id="editGallery"
    :hide-footer="false"
    :hide-header="false"
  >
    <template v-slot:modal-header>
      <div class="flex-total">
        <h2 v-if="dataGallery.id === '0'">Nueva Galeria</h2>
        <h2 v-else>Editar Galeria</h2>
      </div>
      <div class="pointer justify-content-end">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div class="d-flex m-2">
      <div class="m-2">
        <b-row class="my-1">
          <b-col class="p-1" sm="3">
            <label for="input-none">Titulo:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="input-none"
              :state="tituloState"
              placeholder="Titulo"
              v-model="titulo"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col class="p-1" sm="3">
            <label for="input-valid">Descripcion:</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea
              id="input-valid"
              :state="descripcionState"
              placeholder="Descripción"
              v-model="descripcion"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <div class="cardViewconfconf">
          <div id="imgChangeImage" class="hoverr-2 text-white rounded">
            <img
              @click="openSelectorImages"
              style="width: 100%; height: 150px"
              :src="prefill"
            />
          </div>
        </div>
      </div>
      <selectorImagenes @imagen="remplaceImage" id="editarGaleria" />
    </div>
    <template v-slot:modal-footer>
      <b-button :disabled="!
      activeModificarGaleria" @click="saveGallery()" variant="success"
        >{{dataGallery.id === '0' ? 'Crear Galeria': 'Modificar Galeria'}}</b-button
      >
      <b-button @click="$bvModal.hide('editGallery')" variant="danger"
        >Cancelar</b-button
      >
    </template>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../../assets/components/close.vue";
import selectorImagenes from "../dashboard/SelectorImages.vue";
import axios from "axios";

export default {
  name: "editGallery",
  props: ["dataGallery"],

  components: {
    closeicon,
    selectorImagenes,
  },

  data() {
    return {
      titulo: "",
      imagenPortada: "", //La imagen que nos viene y se pinta
      descripcion: "",
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  mounted() {
    if (this.dataGallery) {
      this.titulo = this.dataGallery.tituloNoticia;
      this.imagenPortada = this.dataGallery.imageSrc;
      this.descripcion = this.dataGallery.descripNoticia;
    }
  },
  deactivated() {
    this.titulo = "";
    this.imagenPortada = ""; //La imagen que nos viene y se pinta
    this.descripcion = "";
  },
  watch: {
    dataGallery: function () {
      this.titulo = this.dataGallery.tituloNoticia;
      this.imagenPortada = this.dataGallery.imageSrc;
      this.descripcion = this.dataGallery.descripNoticia;
    },
  },
  computed: {
    activeModificarGaleria(){
      return this.descripcionState && this.tituloState && this.imagenPortada;
    },
    tituloState() {
      return this.titulo && this.titulo.length > 0 ? true : false;
    },
    descripcionState() {
      return this.descripcion && this.descripcion.length > 0 ? true : false;
    },

    //La imagen de la portada que se pinta.
    prefill() {
      return this.imagenPortada
        ? "https://santodomingocartagena.com/img/minGallery/" +
            this.imagenPortada
        : require("@/assets/img/icon/plus-svgrepo-com.svg");
    },
  },
  methods: {
    openSelectorImages() {
      this.$nextTick(() => {
        this.$bvModal.show("editarGaleria");
      });
    },
    remplaceImage(value) {
      this.imagenPortada = value.urlImage;
    },
    saveGallery() {
      if(this.dataGallery.id!= '0'){
        axios({
        method: "put",
        url: `${this.baseApi}/apisd/galeria?actualizaGaleria`,
        headers: { token: this.$cookies.get("user_sessionSD") },
        data: {
          id: this.dataGallery.id,
          titulo: this.titulo,
          descripcion: this.descripcion,
          imagenPortada: this.imagenPortada,
        },
      })
        .then((result) => {
          console.log(result);
          this.$emit("updateGalleries");
        })
        .catch((value) => {
          console.log(value);
        });
      }else{
        axios({
        method: "post",
        url: `${this.baseApi}/apisd/galeria?crearGaleria`,
        headers: { token: this.$cookies.get("user_sessionSD") },
        data: {
          id: this.dataGallery.id,
          titulo: this.titulo,
          descripcion: this.descripcion,
          imagenPortada: this.imagenPortada,
        },
      })
        .then((result) => {
          console.log(result);
          this.$emit("updateGalleries");
        })
        .catch((value) => {
          console.log(value);
        });

      }
    },
    //Arranca el input (oculto)
    changeImage() {
      document.getElementById("uploadImage").click();
    },
    closeModal() {
      this.$bvModal.hide("editGallery");
    },
  },
};
</script>
<style>
#header-contact {
  border-bottom: 1px solid;
}
.justify-content-end {
  margin-right: 0px;
}
#imgChangeImage :hover {
  cursor: pointer;
  zoom: 1.1;
  transition: 0.5s;
}
</style>
