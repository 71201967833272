<template>
    <div v-if="enableWebSite">
        <cabecera class="cabecera" />
        <keep-alive :exclude="['carrete', 'vistaNoticia']">
            <router-view class="body" />
        </keep-alive>
        <footer-site></footer-site>
        <div v-if="popUpChange">
            <popup v-for="(popUp, index) in popUpList" :key="index" :popUp="popUp[0]" />
        </div>
    </div>
    <div v-else>
      <mantenimiento-view />
    </div>
</template>

<script>
import cabecera from "../src/components/header.vue";
import footerSite from "../src/components/footer.vue";
import axios from "axios";
import popup from "../src/components/popUp.vue";
import mantenimientoView from "./Mantenimiento.vue";

export default {
    data() {
        return {
            popUpList: [],
            enableWebSite: true,
            baseApi: location.protocol + "//" + location.hostname,
        };
    },
    metaInfo() {
        return {
            title: "Parroquia Castrense Santo Domingo de Cartagena",
            meta: [{
                    name: "description",
                    content: "Informacion sobre la Parroquia Castrense de Santo Domingo Cartagena.",
                },
                {
                    name: "keywords",
                    content: "santo domingo cartagena, iglesia santo domingo cartagena, parroquia santo domingo cartagena, parroquia castrense de santo domingo, misas cartagena, iglesia castrense de santo domingo, misas santo domingo cartagena",
                },
            ],
            link: [{
                rel: "canonical",
                href: "https://santodomingocartagena.com/",
            }, ],
        };
    },
    name: "homeWebSite",
    props: {
        msg: String,
    },
    components: {
        mantenimientoView,
        cabecera,
        footerSite,
        popup,
    },
    computed: {
        popUpChange() {
            if (this.popUpList.length > 0) { return true } else { return false }
        },
        getDay() {
            var day = new Date().getDate();
            var year = new Date().getFullYear();
            var month = new Date().getMonth() + 1;
            return (
                year +
                "-" +
                (month < 10 ? "0" + month : month) +
                "-" +
                (day < 10 ? "0" + day : day)
            );
        },
    },
    methods: {
        getPopUps() {
            //http://localhost/apisd/popup?today=2023-03-04
            axios({
                    method: "GET",
                    url: `${this.baseApi}/apisd/popup?today=${this.getDay}`,
                    headers: { "Content-Type": "application/json" },
                })
                .then((value) => {
                    if (value.data != "No popUp") {
                        this.popUpList = value.data;
                    }
                })
                .catch((value) => {
                    console.log(value);
                });
        },
    },
    mounted() {
        this.getPopUps();
        // if (!this.$cookies.get("nuevo horario")) {
        //   let path = window.location.hostname;
        //   this.$cookies.set("nuevo horario", undefined,  60 * 60 * 0.2, path);
        //   this.$bvModal.show('popup');
        // }
    },
};
</script>

<style scoped>
#galeryPhoto {
    z-index: -1;
    position: fixed;
    width: 100%;
}

.lastNews {
    height: 100%;
    background: white;
    /* margin-top: 145px; */
    padding-top: 15px;
}

.cardsHome {
    height: 650px;
}

#titleLastNews {
    border-bottom: 1px solid;
    display: initial;
}
</style>
