<template>
  <div id="app">
    <div id="changeWeb" v-if="changeWeb">
      <loadingWebSite />
    </div>
    <div id="webSite" v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import loadingWebSite from "./components/loadingwebsite.vue";

export default {
  name: "App",
  components: {
    loadingWebSite,
  },
  data() {
    return {
      changeWeb: true,
    };
  },
  mounted() {
    window.addEventListener("load", this.checkData());
  },
  methods: {
    checkData() {
      window.setTimeout(this.onWindowLoad, 2000);
    },
    onWindowLoad() {
      this.changeWeb = false;
    },
  },
  computed: {
    changeHeade() {
      if (
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("dashboard")
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
.row {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.cabecera {
  position: fixed;
}
.body {
  z-index: 2;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#webSite {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
