<template>
  <div>
    <b-card class="cards_last_news" >
      <article class="blog-card" @click="redireccion(url)">
        <!-- <img
          
          class="post-image"
          :src="imagen"
          :alt="autor"
        /> -->

        <div class="article-details">
          <!-- titulo-->
          <h3 class="post-title">{{ corrigeTexto(titulo) }}</h3>
          <!-- principio de la noticia-->
          <p class="post-description">{{ corrigeTexto(descripcion) }}</p>
          <!-- boton de redireccion-->
        </div>
        <div class="autor">
          {{ corrigeTexto(autor) }}
        </div>
      </article>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicateDate: undefined,
    };
  },
  name: "cardNews",
  props: [
    "imagen",
    "descripcion",
    "autor",
    "url",
    "key",
    "titulo",
  ],
  components: {},
  methods: {
    redireccion(url){
      window.open(url, '_blank');
    },
    corrigeTexto(texto){
      texto = texto.replaceAll('&aacute;','á');
      texto = texto.replaceAll('&eacute;','é');
      texto = texto.replaceAll('&iacute;','í');
      texto = texto.replaceAll('&oacute;','ó');
      texto = texto.replaceAll('&uacute;','ú');
      return texto;
    }
  },
  computed: {},
};
</script>
<style scoped>
.autor{
  text-align: end;
  font-size: 12px;
  padding-right: 10px;
  color: slategrey;
}
.card-body{
  padding: 0 !important;
}
h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.post-description {
  margin-right: 25px;
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.cards_last_news {
  margin: 15px;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
* {
  box-sizing: border-box;
}

#root {
  width: 30rem;
  height: 13.625rem;
}

.blog-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  overflow: hidden;
  max-height: 500px;
}

.card-link {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
}

img.post-image {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
}

img.post-image:hover {
  transition: 0.5s;
  transform: scale(1.1);
  cursor: pointer;
}

.article-details {
  padding: 1.2rem;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
}
.post-title {
  color: green;
  transition: color 0.3s ease;
  font-size: 0.90rem;
  line-height: 1.4;
  font-weight: 800;
  margin: 0 0 0.5rem 0;
}
.post-button {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}
.post-button:hover {
  color: #e04f62;
  cursor: pointer;
}
@media (max-width: 200rem) {
  #root {
    width: 18rem;
    height: 27.25rem;
  }

  .blog-card {
    flex-wrap: wrap;
  }
}
@supports (display: grid) {
  #root {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }

  .post-image {
    height: 100%;
  }

  .blog-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }

  @media (max-width: 200rem) {
    .blog-card {
      grid-template-columns: auto;
      grid-template-rows: 12rem 1fr;
    }
    .cards_last_news {
      margin: 30px;
    }
  }
}
</style>
