import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    todos: [],
    allImagenes: [],
    allGalerias: [],
    allNoticias: [],
    allNoticiasTotal: [],
    evangelioDia: "",
    lecturaDia:"",
    baseApi: location.protocol + "//" + location.hostname,
  },
  getters: {
    getEvangelioDia(state){
      return state.evangelioDia;
    },
    getLecturaDia(state){
      return state.lecturaDia;
    },
    getAllGalerias(state){
      return state.allGalerias;
    },
    getAllImages(state) {
      return state.allImagenes.reverse();
    },
  },
  mutations: {
    // el encargado de hacer los cambios solicitados desde action
    changeEvangelioDia(state, data){
      state.evangelioDia = data;
    },
    changeLecturaDia(state, data){
      state.lecturaDia = data;
    },

    inicialNoticiasLight(state){
      axios({
        method: "GET",
        url: `${this.baseApi}/apisd/noticias`,
        headers:{"Content-Type": "application/json"},
      })
        .then((data) => {
          state.allNoticias = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    inicialTodasGalerias(state) {
      axios({
        method: "GET",
        url: `${state.baseApi}/apisd/galeria`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          state.allGalerias = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    initialAllImages(state) {
      axios({
        method: "GET",
        url: `${state.baseApi}/apisd/galeria?todasImagenes`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          state.allImagenes = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    updateTodo(state, todo) {
      const index = state.todos.findIndex((item) => item.id == todo.id);
      state.todos.splice(index, 1, {
        id: todo.id,
        title: todo.title,
        completed: todo.completed,
        editing: todo.editing,
      });
    },
  },
  actions: {
    changeLingNoticias(){

    },
    // Son las accionesa hacer, aqui se llama para hacer los cambios necesarios a mutation
    changeAllImages(context) {
      context.commit("initialAllImages");
    },
    initialDataChange(context) {
      context.commit("initialAllImages");
    },
    updateFilter(context, filter) {
      context.commit("updateFilter", filter);
    },
  },
});
